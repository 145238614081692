
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  props: {
    hideText: {
      type: Boolean,
    },
    btnText: {
      type: String,
      default: 'Save',
    },
    modalTitle: {
      type: String,
      default: 'Edit widget',
    },
    name: {
      type: String,
      default: 'InteractBeam',
    },
    domain: {
      type: String,
      default: 'interactbeam.com',
    },
  },
  setup(props) {
    const nameValue = ref(props.name)
    const domainValue = ref(props.domain)

    return {
      nameValue,
      domainValue,
    }
  },
})
